






import { Component, Vue, Prop } from 'vue-property-decorator';
import { BeforeLeaveGuard } from '@/components/mixins/BeforeLeaveGuard';
import { ClientAppSettingKind } from '@/models/client/models';
import { globalStore } from '@/store/modules/global';

@Component({
  mixins: [BeforeLeaveGuard],
})
export default class ClientAppSettingKindForm extends Vue {
  @Prop({ required: true }) id!: string;
  @Prop({ default: '' }) client_app!: string;

  ClientAppSettingKind = ClientAppSettingKind;

  created(): void {
    this.$routerHandler.updateQuery('', {
      organisation: globalStore.selection?.organisation?.id,
    });
  }
}
